import { useMemo, useState } from 'react';

import { validateStepsSchema } from './validateStepsSchema';
import type { WizardType } from './types';

type useCreateWizardProps<
  TSteps extends object,
  TAdditionalState extends object | void
> = {
  id: string;
  initialAdditionalState: TAdditionalState;
  initialState: TSteps;
  initialStep: keyof TSteps;
};

/**
 * @summary : Creates a wizard object with steps, each having its own state and returns functions to retrieve and manipulate the state.
 * @param id : A unique id for the wizard, mainly used for serialization
 * @param initialState : Initial state of your stepped wizard. Must be an object with keys as numbers, throws otherwise. Valid { 1: { a : string } }, invalid { step1: { a : string } }. Initial state is recorded only once, use the functions return by this hook to manipulate state after that.
 * @param initialStep : The step wizard starts on initially. It's up to the consumer to make sure the step states are valid when initial step != 1
 * @param initialAdditionalState :  Initial additional state of your stepped wizard. Must be an object or undefined.
 */
export function useCreateWizard<
  TSteps extends object,
  TAdditionalState extends object | void = void
>(
  props: useCreateWizardProps<TSteps, TAdditionalState>
): WizardType<TSteps, TAdditionalState> {
  const { id, initialState, initialStep, initialAdditionalState } = props;

  const [currentStep, setCurrentStep] = useState(initialStep);
  const [stepsState, setStepsState] = useState<TSteps>(
    validateStepsSchema(initialState)
  );
  const [additionalState, setAdditionalState] = useState<TAdditionalState>(
    initialAdditionalState
  );

  const firstStep = useMemo(() => {
    return Math.min(
      ...Object.keys(stepsState).map((key) => {
        return Number(key);
      })
    );
  }, [stepsState]);

  const lastStep = useMemo(() => {
    return Math.max(
      ...Object.keys(stepsState).map((key) => {
        return Number(key);
      })
    );
  }, [stepsState]);

  function setStepState<TStepsKey extends keyof TSteps>(
    step: TStepsKey,
    cb: (prevState: TSteps[TStepsKey]) => TSteps[TStepsKey]
  ) {
    setStepsState((state) => {
      return { ...state, [step]: { ...cb(state[step]) } };
    });
  }

  function setWizardState(
    cb: (
      prevStepState: TSteps,
      prevAdditionalState: TAdditionalState
    ) => { stepsState: TSteps; additionalStepState: TAdditionalState }
  ) {
    const newState = { ...cb(stepsState, additionalState) };

    setStepsState((state) => {
      return {
        ...state,
        ...newState.stepsState,
      };
    });

    setAdditionalState((state) => {
      return {
        ...state,
        ...newState.additionalStepState,
      };
    });
  }

  function nextStep() {
    const current =
      typeof currentStep !== 'number' ? Number(currentStep) : currentStep;

    if (current > lastStep) {
      return;
    }

    setCurrentStep((step) => {
      return (Number(step) + 1) as keyof TSteps;
    });
  }

  function previousStep() {
    const current =
      typeof currentStep !== 'number' ? Number(currentStep) : currentStep;

    if (current < firstStep) {
      return;
    }

    setCurrentStep((step) => {
      return (Number(step) - 1) as keyof TSteps;
    });
  }

  function _setAdditionalState(
    cb: (prevState: TAdditionalState) => TAdditionalState
  ) {
    setAdditionalState((state) => {
      return { ...state, ...cb(state) };
    });
  }

  return {
    id,
    stepsState,
    additionalState,
    setAdditionalState: _setAdditionalState,
    setWizardState,
    currentStep,
    setCurrentStep,
    nextStep,
    previousStep,
    setStepState,
  };
}
