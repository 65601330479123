export function validateStepsSchema<T extends object>(object: T) {
  if (
    typeof object !== 'object' ||
    !!Object.keys(object).find((key) => {
      return isNaN(Number(key)) || Number(key) === 0;
    })
  ) {
    throw Error('Step keys must be numbers greater than 0 ');
  }

  return object;
}
