import { useEffect } from 'react';
import { cloneDeep, get, isEqual, isObject } from 'lodash';
import { typedKeys } from '@balance-web/utils';

import type { WizardState, WizardType } from './types';
import { isForm } from './isForm';

export const useWizardFormStateSync = <T>(
  wizard: WizardType<T, any>,
  /**
   * Path of form instances from wizard step state you would like to auto-sync. Do not include non-form paths.
   * For example if you wizard state is { 1: { foo: 'string'; bar_form: formInstance }, 2 : { foo: 0; bar_form: formInstance } }, you would pass { 1 : { bar_form: formInstance }, 2: { bar_form: formInstance } } if you want to sync the forms from step 1 and 2 to form state automatically.
   */
  stepForms: RecursivePartial<WizardState<T, never>['stepsState']>
) => {
  useEffect(() => {
    const formPathKeys = findFormPathsInState(stepForms);

    formPathKeys.forEach((key) => {
      const splitKey = key.split('.');
      const currentStepState = get(wizard.stepsState, key);
      const newFormState = get(stepForms, key);
      const step = splitKey[0] as keyof T;

      if (hasStateChanged(currentStepState, newFormState)) {
        wizard.setStepState(step, (prevState) => {
          const newState = cloneDeep(prevState);
          if (splitKey.length === 2) {
            (newState as any)[splitKey[1]] = newFormState;
          } else {
            const leaf = key.split('.').reverse()[0];
            const parent = key.split('.').splice(1);
            get(newState, parent.splice(0, parent.length - 1).join('.'))[
              leaf
            ] = newFormState;
          }
          return newState;
        });
      }
    });
  }, [wizard, stepForms]);
};

const hasStateChanged = (a: any, b: any): boolean => {
  if (isObject(a) && isObject(b)) {
    const isAForm = isForm(a);
    const isBForm = isForm(b);

    if (isForm(a) && isForm(b)) {
      return !isEqual(a.state, b.state);
    }

    if ((isAForm && !isBForm) || (isBForm && !isAForm)) {
      throw Error(
        'Cannot compare state of form and plain objects. There is a mismatch in your state schema.'
      );
    }

    return typedKeys(a).reduce<boolean>((outcome, key) => {
      return outcome || hasStateChanged(a[key], b[key]);
    }, false);
  }

  return !isEqual(a, b);
};

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

const findFormPathsInState = <T extends object>(object: T): Array<string> => {
  const invalidTypes = ['undefined', 'number', 'string'];

  if (invalidTypes.includes(typeof object)) {
    throw new Error('Invalid entry variable.');
  }

  return typedKeys(object).reduce((prev, key) => {
    if (typeof object[key] !== 'object' || isForm(object[key])) {
      return [...prev, key];
    }

    const paths = findFormPathsInState(object[key] as any).map(
      (item: string | number | symbol) => {
        return `${key.toString()}.${
          typeof item === 'symbol' ? item.description : item.toString()
        }`;
      }
    );

    return [...prev, ...paths];
  }, [] as any);
};

export const _useWizardFormStateSync = <T>(
  wizardState: T,
  formState: T,
  updateWizardState: (cb: (prevState: T) => T) => T
) => {
  useEffect(() => {
    const hs = hasStateChanged(wizardState, formState);
    if (hs) {
      updateWizardState(() => {
        return formState;
      });
    }
  }, [formState, wizardState, updateWizardState]);
};
